<template>
    <div class="border">
        <div class="left">{{data.REMARK}}</div>
        <div class="right">
            <div class="item">
                <span class="txt">抓取地址：</span>
                <el-input class="serviceconfig" v-model="data.GRABMAINURL" placeholder="请输入内容"></el-input>
                <a class="sc-visit" :href="data.GRABMAINURL" target="_blank"><img src="@image/dataService_management/visit.png" class="img"></a>
            </div>
            <div class="item2">
                <div class="content">
                    <span class="txt">发布周期：{{ fbzqData }}</span>
                </div>
                <div class="content">
                    <span class="txt">抓取周期：</span>
                    <el-input-number class="servconfinputnum" size="mini" v-model="data.GRABCYCLE"></el-input-number>
                    <el-select class="servconfsel" v-model="data.GRABCYCLEUNIT" placeholder="请选择">
                        <el-option v-for="item in enumData" :key="item.Code" :label="item.Name" :value="item.Code">
                        </el-option>
                    </el-select>
                </div>
                <div class="content">
                    <span class="txt">负责人：</span>
                    <el-select class="serviceconfig-short" v-model="fzrSelected" placeholder="请选择">
                        <el-option v-for="(item,index) in fzrData" :key="`${index}`" :value="item.ID" :label="item.REALNAME"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="item1">
                <el-button type="primary" size="small" class="commonGridButton" @click="saveData">保 存</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import * as TYPES from "@store/modules/mutation-type.js"

export default {
    name: "service-config",
    data() {
        return {
            fbzqData: null,
            value: "",
            fzrSelected: null,
        }
    },
    props: {
        data: {
            default: null,
            type: Object
        }
    },
    methods: {
        ...mapActions([
        ]),
        saveData() {
            // this.data.ID = this.data.ID;
            // this.data.GRABTYPE = this.data.JCXID;
            // this.data.FKEY = this.data.SBID;
            this.data.MANAGERID = this.fzrSelected;
            this.$emit("saveData", this.data);
        },
    },
    computed: {
        ...mapGetters({
            fzrData: TYPES.fzrData,
            enumData: TYPES.enumData
        })
    },
    watch: {
        data() {
            if (this.data.CJZQ && this.data.CJZQDW_DESC) {
                this.fbzqData = this.data.CJZQ + this.data.CJZQDW_DESC;
            } else {
                this.fbzqData = null;
            }
            if (this.data.MANAGERID) {
                this.fzrSelected = this.data.MANAGERID;
            } else {
                this.fzrSelected = null;
            }
        }
    },
    created() {
        if (this.data.CJZQ && this.data.CJZQDW_DESC) {
            this.fbzqData = this.data.CJZQ + this.data.CJZQDW_DESC;
        }
        if (this.data.MANAGERID) {
            this.fzrSelected = this.data.MANAGERID;
        }
    }
}
</script>
<style scoped>
.border {
    display: flex;
    height: 200px;
    margin: 30px;
}

.left {
    width: 50px;
    background-color: #1862ad;
    writing-mode: vertical-lr;
    font-size: 20px;
    color: #FFF;
    letter-spacing: 10px;
    text-align: center;
    line-height: 2.5;
}

.right {
    flex: 1;
    text-align: left;
    background-color: #f2f2f2;
}

.item {
    height: 33%;
    border-bottom: 1px solid #d5d5d5;
    margin-left: 50px;
    margin-right: 30px;
    line-height: 4;
    display: flex;
    align-items: center;
}

.item1 {
    height: 33%;
    margin-left: 50px;
    margin-right: 30px;
    line-height: 4;
}

.txt {
    font-size: 14px;
}

.item2 {
    height: 33%;
    border-bottom: 1px solid #d5d5d5;
    margin-left: 50px;
    margin-right: 30px;
    line-height: 4;
    display: flex;
}

.content {
    flex: 1;
}

.img {
    margin-left: 10px;
}

.commonGridButton.el-button {
    font-weight: 600;
}

.commonGridButton.el-button--primary {
    color: #1862ad;
    background-color: #fff;
    border: 2px solid #1862ad;
}

.commonGridButton.el-button--primary:hover {
    color: #fff;
    background-color: #1862ad;
    border: 2px solid #1862ad;
}

.commonGridButton.el-button--mini,
.commonGridButton.el-button--small {
    font-size: 12px;
    border-radius: 30px;
}

.commonGridButton.el-button--small,
.commonGridButton.el-button--small.is-round {
    padding: 8px 28px;
}

.serviceconfig.el-input {
    width: calc(100% - 105px);
}

.servconfsel.el-select {
    width: 80px;
    margin-left: 6px;
}

.serviceconfig-short.el-select {
    width: 185px;
}

.servconfsel.el-input,
.serviceconfig.el-input,
.serviceconfig-short.el-input,
.servconfsel.el-select,
.serviceconfig.el-select,
.serviceconfig-short.el-select {
    line-height: 28px;
}

.sc-visit {
    line-height: 22px;
}
</style>