//
class JIERU_CONFIG {
    constructor(props) {
        props = props || {};
        /**
         * order: 1
         * is Nullable: NO
         * field Type: varchar(80)
         * field Alias: 主键
         */
        this.ID = props.ID || ""

        /**
         * order: 2
         * is Nullable: NO
         * field Type: varchar(40)
         * field Alias: 抓取类型
         */
        this.GRABTYPE = props.GRABTYPE || ""

        /**
         * order: 3
         * is Nullable: YES
         * field Type: varchar(255)
         * field Alias: 备注
         */
        this.REMARK = props.REMARK || ""

        /**
         * order: 4
         * is Nullable: YES
         * field Type: int(11)
         * field Alias: 抓取周期（单位：秒）
         */
        this.GRABCYCLE = props.GRABCYCLE || 0

        /**
         * order: 5
         * is Nullable: YES
         * field Type: varchar(255)
         * field Alias: 抓取周期单位
         */
        this.GRABCYCLEUNIT = props.GRABCYCLEUNIT || ""

        /**
         * order: 6
         * is Nullable: YES
         * field Type: varchar(50)
         * field Alias: 是否启动(0-未启动,1-启动)
         */
        this.ISACTIVE = props.ISACTIVE || ""

        /**
         * order: 7
         * is Nullable: YES
         * field Type: varchar(50)
         * field Alias: 外键-监测点ID或设备ID
         */
        this.FKEY = props.FKEY || ""

        /**
         * order: 8
         * is Nullable: YES
         * field Type: varchar(50)
         * field Alias: 外键类别0-设备，1-监测点
         */
        this.FKEYTYPE = props.FKEYTYPE || ""

        /**
         * order: 9
         * is Nullable: YES
         * field Type: datetime
         * field Alias: 上次抓取时间
         */
        this.LASTGRABTIME = props.LASTGRABTIME || null

        /**
         * order: 10
         * is Nullable: YES
         * field Type: int(11)
         * field Alias: 抓取误差（秒）
         */
        this.GRABERROR = props.GRABERROR || 0

        /**
         * order: 11
         * is Nullable: YES
         * field Type: varchar(255)
         * field Alias: 数据抓取地址（只有一个抓取url）
         */
        this.GRABMAINURL = props.GRABMAINURL || ""

        /**
         * order: 12
         * is Nullable: YES
         * field Type: varchar(50)
         * field Alias: 负责人id
         */
        this.MANAGERID = props.MANAGERID || ""

        /**
         * order: 13
         * is Nullable: YES
         * field Type: varchar(255)
         * field Alias: 预警数据抓取为位置url
         */
        this.ALERTJUDGEURL = props.ALERTJUDGEURL || ""

        /**
         * order: 14
         * is Nullable: NO
         * field Type: tinyint(4)
         * field Alias: 是否存库
         */
        this.ISSTORAGE = props.ISSTORAGE || 0

    }
    toObject() {
        return {
            ID: this.ID,
            GRABTYPE: this.GRABTYPE,
            REMARK: this.REMARK,
            GRABCYCLE: this.GRABCYCLE,
            GRABCYCLEUNIT: this.GRABCYCLEUNIT,
            ISACTIVE: this.ISACTIVE,
            FKEY: this.FKEY,
            FKEYTYPE: this.FKEYTYPE,
            LASTGRABTIME: this.LASTGRABTIME,
            GRABERROR: this.GRABERROR,
            GRABMAINURL: this.GRABMAINURL,
            MANAGERID: this.MANAGERID,
            ALERTJUDGEURL: this.ALERTJUDGEURL,
            ISSTORAGE: this.ISSTORAGE,
        };
    }
}
export default JIERU_CONFIG;