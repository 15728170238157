<template>
    <div class="base-border">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <el-tabs class="sc-tabs" type="border-card" @tab-click="handleClick">
            <el-tab-pane class="sc-tab-pane scrollbar" v-for="(item,index) in zrhjpzType" :key="`${index}`" :label="item.LX">
                <div class="developing-box" v-if="developingArr.indexOf(index) > -1">
                    <img class="developing-img" :src="developingUrl" />
                </div>
                <service-config v-else v-for="(ele,key) in zrhjpzList" :key="`${key}`" :data="ele" @saveData="saveData"></service-config>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex"
import * as TYPES from "@store/modules/mutation-type.js"
import ServiceConfig from './service_config'
import JIERU_CONFIG from "./model/JIERU_CONFIG.js"

export default {
    name: "service-configuration",
    data() {
        return {
            itemName: '服务配置',
            breadlist: [
                {
                    path: '',
                    name: '数据管理与接入'
                }, {
                    path: '',
                    name: '配置管理'
                }
            ],
            tabSelected: null,
            developingUrl: require("@image/public/not-development.png"),
            developingArr: [0, 2, 3],
        }
    },
    components:{
        ServiceConfig
    },
    computed: {
        ...mapGetters({
            zrhjpzType: TYPES.zrhjpzType,
            zrhjpzList: TYPES.zrhjpzList
        })
    },
    methods: {
        ...mapActions([
            'getZrhjpzType',
            'getZrhjpzList',
            'getEnumData',
            'getFZR',
            'saveJieruConfig'
        ]),
        // eslint-disable-next-line no-unused-vars
        handleClick(tab, event) {
            let type = tab.label;
            this.tabSelected = type;
            this.getZrhjpzList({type: type});
        },
        async saveData(val) {            
            let data = new JIERU_CONFIG(val);
            if (!data) {
                return;
            }
            let result = await this.saveJieruConfig({
                jsonStr: data
            });
            
            if (result) {
                this.showSuccess();
                let type = this.tabSelected;
                this.getZrhjpzList({type: type});
            } else this.showError();
        },
        //保存成功提示
        showSuccess() {
            this.$message({
                message: "保存成功",
                type: "success"
            });
        },
        //保存不成功提示
        showError() {
            this.$message({
                message: "保存失败",
                type: "warning"
            });
        },
    },
    watch: {
        zrhjpzType() {
            if (!this.zrhjpzType) return;
            if (this.zrhjpzType.length == 0) return;
            let type = this.zrhjpzType[0].LX;
            this.tabSelected = type;
            this.getZrhjpzList({type: type});
        }
    },
    created() {
        // 获取自然环境配置类别-服务配置
        this.getZrhjpzType();
        this.getEnumData({_tableId: "t_sb_jieru_config"});
        this.getFZR({
            _typeId: "114",
            _cols: "",
            _where: "",
            _orderby: "",
            _pageSize: null,
            _pageIndex: null,
            _returnSum: true,
            _args: ""
        });
    }
}
</script>
<style scoped>
.base-border{
    /* width: calc(100% - 170px);
    height: calc(100% - 30px);
    margin: 15px 85px; */
    width: 100%;
    height: 100%;
}
.sc-tabs{
    height: calc(100% - 70px);
    margin: 15px 0 0;
}
.sc-tab-pane.el-tab-pane{
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
.developing-box{
    position: relative;
    width: 100%;
    height: 100%;
}
</style>
